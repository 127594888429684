import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'

const ArticlePage = ({location}) =>
  (
    <Layout>

      <SEO title="Award-winning service"
        description="We are thrilled to have been recognised by the retail and design industry for the service we are providing to prestige car sellers. "
        location={location}
      />

      <PageHeader
        title="Award-winning service"
        text="We are thrilled to have been recognised by the retail and design industry for the service we are providing to prestige car sellers. "
      />

      <SectionPageMargin>

          <Heading2>Quality, innovation & service</Heading2>

          <Paragraph1>
            In the past few weeks, Summon has been recognised in the 2021 Online Retail Industry Awards (ORIAS), and also in the DRIVENxDESIGN Award Programs – 2021 the Melbourne Design Awards.
          </Paragraph1>

          <Paragraph1>
            We have been recognised as finalists in two categories in the <a href="https://www.onlineretailer.com/en-gb/orias.html">ORIAS</a>, with the judging underway and the award winners being announced in late September 2021.
          </Paragraph1>

          <Paragraph1>
            Summon are finalists in the <strong>Best Phygital Initiative</strong> category (for “fantastic examples of technologies and innovations that have successfully blended physical with digital to add value for the customer and serve a great business need”) and also the <strong>Best Small Independent Retailer</strong> category (which recognises “the achievements of small ecommerce exemplars who demonstrate best practices in ecommerce, innovation and growth, with an effective business strategy”
          </Paragraph1>

          <Paragraph1>
            In addition, we have received the Gold Award in the <a href="https://drivenxdesign.com/MEL21/project.asp?ID=21675">2021 Melbourne Design Awards</a>, for the <strong>Digital - New Service or Application</strong> category. This award recognises “true innovation, exceeding expectations and filling a void that had previously been open”.
          </Paragraph1>

          <Paragraph1>
            These awards are a true testament to the entire Summon team, who are dedicated to giving our customers a new and different (and better!) way to sell their prestige car.
          </Paragraph1>

      </SectionPageMargin>

     <ReadyToGetStarted />

    </Layout>

  )

export default ArticlePage
